

import { request } from '@/common/request';

export const deal = {
    timestamp: function (nS, type) {
        return type == 'unix' ? formatDate(new Date(parseInt(nS) * 1000)) : (new Date(nS)).getTime() / 1000;

        function formatDate(now) {
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 9) {
                month = "0" + month;
            }
            var date = now.getDate();
            if (date < 9) {
                date = "0" + date;
            }
            var hour = now.getHours();
            if (hour < 9) {
                hour = "0" + hour;
            }
            var minute = now.getMinutes();
            if (minute < 9) {
                minute = "0" + minute;
            }
            var second = now.getSeconds();
            if (second < 9) {
                second = "0" + second;
            }
            return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
        }
    },
    getconf(text, _this) {
        request.get('/common/conf', { position: text, page: 1, pagesize: -1 }).then(ret => {
            if (ret.code == 1) {
                _this.loading = false;
                _this.positionList = ret.data.list
                _this.platformList = ret.data.platform
            } else {
                this.$message.error(ret.msg);
            }
        });
    },

    payTypeFormat: function (row) {
        switch (row.pay_type) {
            case 'qianbao':
                return "余额支付"
                break;
            case 'weixinapp':
                return "微信app支付"
                break;
            case 'weixinh5':
                return "微信h5支付"
                break;
            case 'weixinmp':
                return "微信小程序支付"
                break;
        }

    },
    imageFormat(file, _this, size = 300) {
        console.log(size)
        console.log(3333, file.type)
        const isIMG =
            file.type === 'image/jpg' ||
            file.type === 'image/jpeg' ||
            file.type === 'image/png'
        const isLt = file.size / 1024 <= size
        if (!isIMG) {
            _this.$message.error('上传图片只支持jpg、jpeg、png格式!')
            return false
        }
        /*if (!isLt) {
            _this.$message.error(`上传图片大小不能超过${size}KB!`)
            return false
        }*/
    },

    checkUrl(rule, value, callback) {
        if (!value) {
            return callback();
        } else {
            var strRegex = "^(https://|http://|plugin-private://|/)"
            var re = new RegExp(strRegex);
            if (re.test(value)) {
                return callback();
            } else {
                value = "";
                return callback(new Error('请输入正确的链接地址'));
            }
        }
    },
    checkImage(rule, value, callback) {
        if (!value) {
            return callback();
        } else {
            var strRegex = '^((https|http)?://)'
            var re = new RegExp(strRegex);
            if (re.test(value)) {
                // if (!/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/.test(value)) {
                //     return callback(new Error('上传的所有文件必须是【.jpeg、.jpg、.png】类型的图片!"'));
                // }
                value = "";
                return callback();

            } else {
                value = "";
                return callback(new Error('请输入正确的图片地址'));
            }
        }
    },
    checkVideo(rule, value, callback) {
        if (!value) {
            return callback();
        } else {
            var strRegex = '^((https|http)?://)'
            var re = new RegExp(strRegex);
            if (re.test(value)) {
                if (!/\.(mp4)$/.test(value)) {
                    return callback(new Error('上传的所有文件必须是【.mp4】类型的图片!"'));
                }
                value = "";
                return callback();

            } else {
                value = "";
                return callback(new Error('请输入正确的视频地址'));
            }
        }
    },
    checkSfzhmcode(rule, value, callback) {
        if (!value) {
            return callback(new Error('身份证号码不能为空'));
        } else {
            var strRegex = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
            if (strRegex.test(value)) {
                return callback();

            } else {
                value = "";
                return callback(new Error('请输入正确的身份证号码'));
            }
        }
    },
    checkFile(rule, value, callback) {
        if (!value) {
            return callback(new Error('文件地址不能为空'));
        } else {
            var strRegex = '^((https|http)?://)'
            var re = new RegExp(strRegex);
            if (re.test(value)) {
                if (!/\.(html)$/.test(value)) {
                    return callback(new Error('上传的所有文件必须是【.html】类型的图片!"'));
                }
                value = "";
                return callback();

            } else {
                value = "";
                return callback(new Error('请输入正确的文件地址'));
            }
        }
    },
};