export const BASE_URL_PRO = "https://swarovski-api.lihansy.com.cn"
// export const BASE_URL_PRO = "https://swarovski-api.lihansy.com.cn"
//export const BASE_URL_PRO = "https://supply-platform-api.lihansy.com.cn"
/*  const BASE_URL_PRO= "http://test-hsz.xingpinmao.com/i/"
  const BASE_URL_DEV="http://test-hsz.xingpinmao.com/"
export const CommonTagTypeUserInfo  = 1 //用户资讯标签*/
export const IMAGE_UPLOAD_LOCAL_URL = "/i/admin/system/upload/local" //上传到服务器本地
export const IMAGE_UPLOAD_OSS_URL = "/i/admin/system/upload" //上传到服务器 oss服务
export const IMAGE_UPLOAD_PRO_URL = IMAGE_UPLOAD_OSS_URL //正式环境 上传到服务器


// 中台测试接口
export const ztUrl = 'http://test-zt-api.lihansy.com.cn:88/admin'
// 中台正式接口
// export const ztUrl = 'http://zt-api.lihansy.com.cn:88/admin'


export const CommonTagTypeUserInfo = 1 //用户资讯标签
export const CommonTagTypeUserHobby = 2 //用户喜好标签
export const CommonTagTypeGoods = 10//商品标签
export const CommonTagTypeAdmin = 20//管理员标签


export const CommonStateOk = 2//管理员标签

//标签 类型
export const CommonTagTypeDict = {
    0: "未分类",
    1: "用户资讯标签",
    2: "用户喜好标签",
    10: "商品标签",
    20: "管理员标签",
}

//标签 类型
export function CommonTagTypeMap(typers) {
    let cateStr = CommonTagTypeDict[typers]
    if (cateStr == null || cateStr === "") {
        cateStr = "未分类"
    }
    return cateStr
}


export const StyleModeList = [{
    name: "样式0",
    modeValue: "0",
    thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/1g1Xa561ec52bd957b5aef4765b2aff67471ee5.png",
    isActive: false,
}, {
    name: "样式1",
    modeValue: "1",
    thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/G8TKN565836a1cea8fb6ed93d8de5111d724b91.png",
    isActive: false,
}, {
    name: "样式2",
    modeValue: "2",
    thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/tJ7HP565117432dcf630984eb9dd18c20d84f80.png",
    isActive: false,
}, {
    name: "样式3",
    modeValue: "3",
    thumb: "https://lihansy.oss-cn-beijing.aliyuncs.com/admin/image/Cuffq56131a869710adc59e9dd91092b7e0cfc2.png",
    isActive: false,
},
]

export default {
    IMAGE_UPLOAD_LOCAL_URL, IMAGE_UPLOAD_OSS_URL, CommonTagTypeUserInfo, CommonTagTypeUserHobby,
    CommonTagTypeGoods, CommonTagTypeAdmin,
    CommonTagTypeDict, CommonTagTypeMap
}

