<template>
    <el-dialog title="选择图片" :visible.sync="currenImgVisible" @close="close" class="dial">
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="选择时间:">
                    <el-date-picker ref="drawDate" style="margin-left: 5px;" v-model="queryParams.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable @change="changeQuery">
                    </el-date-picker>
                </el-form-item>
                <div style="float: right" class="dialog_box">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>

                    <el-upload class="avatar-uploader" multiple :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        上传图片
                    </el-upload>

                    <el-button style="margin-left: 10px" v-if="multiple" type="primary" @click="finish()">完成</el-button>
                </div>
            </el-form>
            <!-- 图片列表 -->
            <div class="image-list">
                <div class="image-item" @click="chose(item.url)" v-for="(item,index) in pageInfo.list" :key="index">
                    <div :class="selImgs.indexOf(item.url) >= 0 ? 'sel-img':''">
                        <img :src="item.url" />
                        <span class="remove-btn" @click="remove(item.id)">
                            <i class="el-icon-delete btn"></i>
                        </span>
                    </div>
                </div>
            </div>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
    </el-dialog>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import { request } from '@/common/request';
import { deal } from '@/common/main';
import {IMAGE_UPLOAD_PRO_URL} from "../common/const";
let loads = new loadEvents();
export default {
    name: 'Picture',
    components: {
        Breadcrumb,
        Pagination,
    },
    props: { // 声明自定义属性
        ImgVisible: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            tempImg: '',
            currenImgVisible: false,
            header: {},
            menuList: ['系统', '图片资源'],
            queryParams: {
                page: 1,
                pagesize: 18
            },
            loading: false,
            pageInfo: {},
            title: '加载中',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            form: {},
            selImgs: []
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    },
    watch: {
        // 监听 addOrUpdateVisible 改变
        ImgVisible(oldVal, newVal) {
            this.currenImgVisible = this.ImgVisible
        },
    },
    //图片上传
    methods: {
        // img选择
        chose(url) {
            if (this.multiple) {
                let index = this.selImgs.indexOf(url);
                if (index > -1) {
                    this.selImgs.splice(index, 1);
                } else {
                    this.selImgs.push(url)
                }
            } else {
                if (url) {
                    this.$emit('getImg', url);
                }
                this.close()
            }
        },
        close() {
            this.$emit('toggleImg', false);
        },
        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.loadData();
                console.log("上传成功")
            }
        },
        // 查看大图
        // showBigImg(index) {
        //     this.$nextTick(function () {
        //         let shows = document.getElementsByClassName(`column${index}`)
        //         let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
        //         show[0].style['display'] = 'block'
        //         let dom = show[0].getElementsByClassName('el-image-viewer__mask')
        //         dom[0].addEventListener('click', function () {
        //             show[0].style['display'] = 'none'
        //         })
        //     })
        // },
        beforeAvatarUpload(file) {
            loads.open('.avatar-uploader .el-upload', '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        //图片列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/common/picture/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            if (this.queryParams.time) {
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                delete this.queryParams.time
            }
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            if (this.queryParams.time) {
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                delete this.queryParams.time
            }
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            if (this.queryParams.time) {
                this.queryParams.ctime = deal.timestamp(this.queryParams.time[0]);
                this.queryParams.etime = deal.timestamp(this.queryParams.time[1]);
                delete this.queryParams.time
            }
            this.loadData();
        },
        // 删除图片
        remove(id) {
            request.get('/common/picture/remove', { id: id }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('删除成功');
                    this.loadData()
                }
            });
        },
        finish() {
            if (this.selImgs.length > 0) {
                this.$emit('getImgs', this.selImgs);
                this.selImgs = []
                this.close()
            } else {
                this.$message({ type: 'error', message: '至少选择一张图片' })
            }

        },
    }
};
</script>

<style scoped>
.image-list {
    width: 100%;
}
.image-item {
    display: inline-block;
    margin: 4% 0 0 4%;
    width: 12%;
    height: 100px; /*当图片尺寸不一的时候，设置一个高度*/
    overflow: hidden;
    position: relative;
    background-color: #dddddd;
}
.image-item img {
    width: 100%;
    vertical-align: middle;
}
.remove-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    padding: 2px;
    cursor: pointer;
}
.image-item:hover .remove-btn {
    opacity: 1;
}
.avatar-uploader >>> .el-upload {
    padding: 9px 15px;
    color: #fff;
    font-size: 12px;
    border: none !important;
}
.avatar-uploader {
    background-color: #409eff;
    margin-left: 20px;
    border-radius: 3px;
}
.dialog_box {
    display: flex;
}
.sel-img {
    height: 100%;
    border: 2px solid #409eff;
}
</style>
