<template>
    <div class="block pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       v-bind:current-page="pageInfo.page"
                       v-bind:page-size="pageInfo.pagesize"
                       v-bind:total="pageInfo.count"
                       :page-sizes="[10, 20, 50]"

                       layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
    </div>
</template>
<script>
    /* 再此定义JS，采用ES6标准格式 */
    export default {
        name: 'Pagination',
        props: ['pageInfo'], // 声明自定义属性
        //过滤返回关键字
        filters: {

        },
        methods: {
            handleSizeChange(val) {
                // 调用刷新pageSize事件
                this.$emit('refreshPageSize', val)
            },
            handleCurrentChange(val) {
                // 调用刷新pageNum事件
                this.$emit('refreshPageNum', val)
            },
            deal_int(item) {
                return parseInt(item);
            }
        },
    }
</script>
<style>
    /* 在此定义当前页面css */
    .pagination {
        margin-top: 10px
    }
</style>
