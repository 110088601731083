<template>
	<el-breadcrumb separator="/">
		<el-breadcrumb-item v-for="(item, index) in menuList" :key="item">
			<i v-if="index === 0" class="el-icon-lx-calendar"></i>
			{{item}}
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>
<script>
    export default {
        name: 'Breadcrumb',
        props: ['menuList'],
        data () {
            return {
            }
        }
    }
</script>
<style scoped>
</style>
