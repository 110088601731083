import { Loading } from 'element-ui';
const loadOption = { fullscreen: false, lock: true, text: 'Loading', spinner: 'el-icon-loading', target: document.querySelector('.avatar-uploader .el-upload'), background: 'rgba(0, 0, 0, 0.7)' }
let loadingInstance;

export default class loadEvents {
  constructor(vueThis) {
    this.vm = vueThis;  //vue中的this  也可以不用
  }
  open (target, text = '上传中') {
    loadingInstance = Loading.service({
      fullscreen: false,
      lock: true,
      text: text,
      spinner: 'el-icon-loading',
      target: document.querySelector(target),
      background: 'rgba(0, 0, 0, 0.7)'
    });
  }
  close () {
    loadingInstance.close();
  }
}